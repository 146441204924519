import React from 'react';
import styled from 'styled-components';

import { Layout } from '../../components/shared/layout';
import { Page } from '../../components/shared/Page';
import { FAQBanner, FAQListContainer } from '../../components/modules/FAQPage';

const StyledFAQPage = styled(Layout)`
  text-align: center;
`;

const FAQPage = () => (
  <StyledFAQPage headerTransparent>
    <Page>
      <FAQBanner />
      <FAQListContainer type="business" />
    </Page>
  </StyledFAQPage>
);

export default FAQPage;
